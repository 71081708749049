import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { digitalGalleryStore } from './redux/index';
import { BrowserRouter } from 'react-router-dom';
import { RouterToUrlQuery, addUrlProps, UrlQueryParamTypes, subquery, configureUrlQuery, urlQueryMiddleware } from 'react-url-query';
import DigitalGalleryContainer from './components/digital-gallery-container';

render(
    <Provider store={digitalGalleryStore}>
        <DigitalGalleryContainer />
    </Provider>
    , document.querySelector("[data-digital-gallery-page]"));