import * as React from 'react';
import { connect } from 'react-redux';
import { DigitalShelfItem } from '../classes/digital-shelf-item';

export interface ExternalProps {
    item: DigitalShelfItem;
}

type ShelfItemProps = ExternalProps;

export class ShelfItem extends React.Component<ShelfItemProps, null> {
    constructor(props: ShelfItemProps) {
        super(props);
    }

    render() {
        return (
            <a href={this.props.item.linkUrl} className="swiper-slide gallery-shelf-slide un-button unlink">
                <div className="gallery-shelf-slide-inner">
                    <img src={this.props.item.imageUrl} alt={this.props.item.alt} />
                    <div className="overlay"></div>
                    <div className="gallery-shelf-slide-inner-content">
                        <span className="h6-content-heading shelf-title">{this.props.item.title} </span>
                        <span className="shelf-description small" >{this.props.item.dateTimeString}</span>
                    </div>
                </div>
                <div className="gallery-shelf-slide-feature background">
                    <div className="gallery-shelf-slide-feature-content">
                        <span className="h6-content-heading shelf-title">{this.props.item.title}</span>
                        <span className="shelf-description small" >{this.props.item.dateTimeString}</span>
                    </div>
                    <div className="gallery-shelf-slide-feature-play">
                        <div className="gallery-shelf-slide-feature-play-icon"></div>
                    </div>
                </div>
            </a>
        );
    }
}

export default connect(null, null)(ShelfItem);