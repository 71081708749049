import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import { DigitalGalleryAppState } from '../classes/redux-states';
import { Shelf } from '../classes/shelf';
import { ShelfComponent } from './shelf';

export interface ExternalProps {
    shelfList: Shelf[];
}

interface StateToProps {
    state: DigitalGalleryAppState;
}

type ShelfListProps = ExternalProps & StateToProps;

export class ShelfListComponent extends React.Component<ShelfListProps, null> {
    constructor(props: ShelfListProps) {
        super(props);
    }

    render() {
        return (
            <div className="galley-shelf-container">
                {
                    this.props.shelfList.map((shelf, index) => <ShelfComponent shelf={shelf} key={index} />)
                }
            </div>
        );
    }
}

function mapStateToProps(state: DigitalGalleryAppState): StateToProps {
    return {
        state: state
    }
}

export default connect(mapStateToProps, null)(ShelfListComponent);