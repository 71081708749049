import * as React from 'react';
//import ajaxLoader from "../static/ajaxLoader.gif";

export class LoadingComponent extends React.Component {

    render() {
        return (
            <div className="digital-gallery-loading loadingContainer bgOverlay">
                <p>Loading...</p>
                <img className="loadingSpinner" src="/app-react/season-overview-page/static/ajaxLoader.gif" />
            </div>
        )
    }
}