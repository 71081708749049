import { combineReducers, createStore, applyMiddleware } from 'redux';
import { Store } from 'react-redux';
import thunk from 'redux-thunk';
import digitalGalleryApiReducer from './reducers/gallery-reducer';
import digitalGallerySettingsReducer from './reducers/settings-reducer';
//add api imports and app state import

let combined = combineReducers({
    digitalGalleryApiModel: digitalGalleryApiReducer,
    digitalGallerySettingsModel: digitalGallerySettingsReducer
});

var digitalGalleryStore = createStore(combined, applyMiddleware(thunk)) as Store<any>;

var previousState = digitalGalleryStore.getState();

digitalGalleryStore.subscribe(() => {
    const currentState = digitalGalleryStore.getState();

    previousState = currentState;
});

export { digitalGalleryStore }