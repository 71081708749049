import { assign, groupBy } from 'lodash';
import { IAction } from '../../classes/action';
import { Shelf } from '../../classes/shelf';
import { ActionType } from '../actions/actions';

export interface DigitalGalleryApiModel {
    requestingData: boolean;
    shelfList: Shelf[];
}

export const defaultDigitalGalleryApiModel: DigitalGalleryApiModel = {
    requestingData: true,
    shelfList: []
} 

export default function digitalGalleryApiReducer(state: DigitalGalleryApiModel = defaultDigitalGalleryApiModel, action: IAction) {
    switch (action.type) {
        case ActionType.REQUEST_DIGITAL_ITEMS: {
            return assign({}, state, { requestingData: true });
        }
        case ActionType.RECEIVE_DIGITAL_ITEMS: {
            const shelfList = action.value.shelfList;

            return assign({}, state, { requestingData: false, shelfList: shelfList });
        }
        default:
            return state;
    }
}