import * as _ from 'lodash';
import { ActionType } from '../actions/actions';
import { Shelf } from '../../classes/shelf';
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'react-redux';
import { DigitalGalleryAppState } from '../../classes/redux-states';

export function requestDigitalGallery() {
    return {
        type: ActionType.REQUEST_DIGITAL_ITEMS
    }
}

interface DigitalGalleryResponse {
    shelfList: Shelf[];
}

export function digitalGalleryResponse(response: DigitalGalleryResponse) {
    return {
        type: ActionType.RECEIVE_DIGITAL_ITEMS,
        value: {
            shelfList: response.shelfList
        }
    }
}

export function getDigitalGallery() {
    return (dispatch: Dispatch<DigitalGalleryAppState>, getState: () => DigitalGalleryAppState) => {
        let state = getState();
        let id = state.digitalGallerySettingsModel.pageId;
        dispatch(requestDigitalGallery());
        axios.post<DigitalGalleryResponse>('/api/DigitalGallery/getShelves',
            {
                PageId: id
            }).then(response => {
                const galleryData = response.data;
                state = getState();
                dispatch(digitalGalleryResponse(galleryData));
            });
    }
}