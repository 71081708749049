import { assign, groupBy } from 'lodash';
import { IAction } from '../../classes/action';
import { ActionType } from '../actions/actions';

export interface DigitalGallerySettingsModel {
    displayUserItems: boolean;
    pageId: string;
}

export const defaultDigitalGallerySettingsModel: DigitalGallerySettingsModel = {
    displayUserItems: false,
    pageId: ''
}

export default function digitalGallerySettingsReducer(state: DigitalGallerySettingsModel = defaultDigitalGallerySettingsModel, action: IAction) {
    switch (action.type) {
        case ActionType.SET_PAGE_ID: {
            const pageId = action.value;
            return assign({}, state, { pageId: pageId });
        }
        default:
            return state;
    }
}

export function updatePageId(pageId: string) {
    return {
        type: ActionType.SET_PAGE_ID,
        value: pageId
    }
}