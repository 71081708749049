import Swiper, { Navigation } from 'swiper';
import debounce = require('lodash.debounce');
import { nodeListToArray } from '../../helpers/nodeListToArray';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class ShelfCarousel {
    initCarousel: Swiper;
    constructor(element: HTMLElement) {
        let uniqueId = element.getAttribute('data-unique-id');
        this.initCarousel = new Swiper(element, {
            slidesPerView: 4.25,
            spaceBetween: 12,
            modules: [Navigation],
            navigation: {
                nextEl: `.btn-next-${uniqueId}`,
                prevEl: `.btn-prev-${uniqueId}`,
            },
            autoHeight: false,
            loop: false,
            watchOverflow: true,
            breakpoints: {
                1280: {
                    slidesPerView: 4.25,
                },
                768: {
                    slidesPerView: 2.25,
                },
                100: {
                    slidesPerView: 1.25,
                }
            }
        })
    }
}


