import * as React from 'react';
import { connect } from 'react-redux';
import { DigitalGalleryAppState } from '../classes/redux-states';
import { DigitalGallerySettingsModel, updatePageId } from '../redux/reducers/settings-reducer';
import { Dispatch } from 'redux';
import { LoadingComponent } from './loading-component';
import ShelfListComponent from './shelf-list';
import { getDigitalGallery } from '../redux/actions/digital-gallery-api';

interface StateToProps {
    settings: DigitalGallerySettingsModel;
    state: DigitalGalleryAppState;
    requestingData: boolean;
}

interface DispatchToProps {
    updatePageId: (pageId: string) => any;
    getDigitalGallery: () => any;
}

type DigitalGalleryContainerProps = StateToProps & DispatchToProps;

export class DigitalGalleryContainer extends React.Component<DigitalGalleryContainerProps> {
    constructor(props: DigitalGalleryContainerProps) {
        super(props);
        var pageId = (document.getElementById("pageId") as HTMLInputElement).value;
        this.props.updatePageId(pageId);
        this.props.getDigitalGallery();
        this.getGalleryMarkup = this.getGalleryMarkup.bind(this);
    }

    getGalleryMarkup() {
        return this.props.requestingData ? <LoadingComponent /> : <ShelfListComponent shelfList={this.props.state.digitalGalleryApiModel.shelfList} />;
    }

    render() {
        return (
            <div>
                {this.getGalleryMarkup()}
            </div>
        );
    }
}

function mapStateToProps(state: DigitalGalleryAppState): StateToProps {
    return {
        requestingData: state.digitalGalleryApiModel.requestingData,
        settings: state.digitalGallerySettingsModel, 
        state: state
    }
}

function mapDispatchToProps(dispatch: Dispatch<DigitalGalleryAppState>): DispatchToProps {
    return {
        getDigitalGallery: () => dispatch(getDigitalGallery()),
        updatePageId: (pageId: string) => dispatch(updatePageId(pageId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalGalleryContainer);