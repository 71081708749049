import * as React from 'react';
import { connect } from 'react-redux';
import { DigitalGalleryAppState } from '../classes/redux-states';
import { Dispatch } from 'redux';
import { Shelf } from '../classes/shelf';
import { ShelfItem } from './shelf-item';
import { ShelfCarousel } from '../../../Static/javascript/sfj/specific/shelf-carousel';
import { nodeListToArray } from '../../../Static/javascript/helpers/nodeListToArray'

export interface ExternalProps {
    shelf: Shelf;
}

type ShelfProps = ExternalProps;

export class ShelfComponent extends React.Component<ShelfProps, null> {
    constructor(props: ShelfProps) {
        super(props);
    }

    componentDidMount() {
        const homeCarousels = nodeListToArray(document.querySelectorAll('[data-events-gallery]')) as HTMLElement[];
        homeCarousels.forEach(carousel => {
            new ShelfCarousel(carousel);
        });
    }

    render() {
        return (
            <div className={`gallery-shelf ${this.props.shelf.themeColor}`}>
                <div className="gallery-app-border">
                    <span></span>
                </div>
                <div className="swiper" data-events-gallery data-unique-id={this.props.shelf.shelfId}>
                    <div className="gallery-app-header">
                        <div className="gallery-shelf-header">
                            {
                                this.props.shelf.shelfLink != "" ?
                                    <a href={this.props.shelf.shelfLink}>
                                        <h2 className="h5-paragraph-heading gallery-shelf-title">{this.props.shelf.shelfName}</h2></a>
                                    : 
                                    <h2 className="h5-paragraph-heading gallery-shelf-title">{this.props.shelf.shelfName}</h2>
                            }
                        </div>
                        
                        
                        <p className="rich-text">{this.props.shelf.shelfDescription}</p>
                    </div>
                    
                    <div className="btn-arrows">
                        <button className={`btn-prev-${this.props.shelf.shelfId} ` + "btn-arrow btn-arrow-prev"} type="button" aria-label="Previous slide">
                            <svg focusable="false" role="presentation" aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-arrow-right"></use>
                            </svg>
                        </button>
                        <button className={`btn-next-${this.props.shelf.shelfId} ` + "btn-arrow btn-arrow-next"} type="button" aria-label="Next slide">
                            <svg focusable="false" role="presentation" aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-arrow-right"></use>
                            </svg>
                        </button>
                    </div>
               
                        <div className="swiper-wrapper">
                            {this.props.shelf.digitalShelfItems.map((item, index) => <ShelfItem key={index} item={item} />)}
                        </div>
                    
                </div>
            </div>
        );
    }
}

export default connect(null, null)(ShelfComponent);